import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill practice: Rope Climbs`}</p>
    <p>{`(with legs, legless, Seated L-Hold)`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time:`}</p>
    <p>{`2-Rope Climbs to 15’ (Rx+=legless)`}</p>
    <p>{`1 Lap Run (480m’s)`}</p>
    <p>{`20- V Ups`}</p>
    <p>{`20- Push ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      